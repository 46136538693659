import React from "react";

const Metadata = ({pageData}) => {
    return (
        <>
            <title>{pageData?.meta_title || "Welcome"}</title>
            <meta
                property="og:description"
                content={pageData?.meta_description || ""}
            />
            <meta
                property="og:image"
                content={pageData?.meta_image.url || ""}
            />
        </>
    );
};

export default Metadata;

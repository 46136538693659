import * as Yup from "yup";

const EMAIL_ERROR = "Please enter a valid email address";

const homePageContactSchema = Yup.object().shape({
  email: Yup.string().email(EMAIL_ERROR).required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string(),
  phone: Yup.string().required("Required"),
});

export { homePageContactSchema };

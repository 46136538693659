// extracted by mini-css-extract-plugin
export var active = "index-module--active--034df";
export var dropdown = "index-module--dropdown--98f10";
export var dropdownCenter = "index-module--dropdown-center--6db58";
export var dropdownItem = "index-module--dropdown-item--74c23";
export var dropdownMenu = "index-module--dropdown-menu--1b038";
export var dropend = "index-module--dropend--dc5dd";
export var dropstart = "index-module--dropstart--0ab18";
export var dropup = "index-module--dropup--d2ab0";
export var dropupCenter = "index-module--dropup-center--c2c70";
export var industry_details_wrapper = "index-module--industry_details_wrapper--c33a7";
export var navItem = "index-module--nav-item--6b2c3";
export var navLink = "index-module--nav-link--bb54f";
export var navbarExpandMd = "index-module--navbar-expand-md--13713";
export var navbarNav = "index-module--navbar-nav--e7706";
export var navbarToggler = "index-module--navbar-toggler--cd5d2";
export var navbarTogglerIcon = "index-module--navbar-toggler-icon--8bdae";
export var sectionCenter = "index-module--section-center--1e503";
export var sectionContainer = "index-module--section-container--e33d1";
export var section_cel = "index-module--section_cel--7d2a7";
export var section_wrapper = "index-module--section_wrapper--75746";
export var serviceTab = "index-module--serviceTab--83c7b";
export var theam = "index-module--theam--c0955";
export var topContentBox = "index-module--topContentBox--9274c";
export var topImageBox = "index-module--topImageBox--df99a";
export var topOuterBox = "index-module--topOuterBox--dc5f8";
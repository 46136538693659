import React, {useState,useEffect} from "react";
import StyledComponent from "../../../../components/StyledComponent";
import {Tab, Row, Col} from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import * as style from './index.module.scss';
import VectorArrow from "../../../../images/icons/vectorRightArrow.svg"
import ServiceImg from "../../../../images/Services - Maintenance.jpg";
import ServiceImg_1 from "../../../../images/service_1.png";
import ServiceImg_2 from "../../../../images/Services - Minor works.jpg";
import ServiceImg_3 from "../../../../images/Services - Staffing Services.jpg";
import PropertyIcon from "../../../../images/icons/propertyIcon.svg";
import {PrismicRichText} from "@prismicio/react";

const ServicesSection = ({data}) => {

    const [imageClicked, setImageClicked] = useState(data?.s_card?.[0]?.s_card_image?.url);

    const [status, setStatus] = useState(0);

    const onClickHandler = (k) => {
        setImageClicked(k);
    }

    let section = (
        <section id="services" className={style.sectionService}>
            <div className="section-center">
                <PrismicRichText field={data?.s_title}/>
                <div className={style.outerBox}>
                    <div className={style.imgBox}>

                        <img src={imageClicked}
                             data-aos="zoom-in-down"/>

                        <div className={style.greenBox}
                             data-aos="zoom-in-up"></div>
                    </div>
                    <div className={style.bottomContent}>
                        <Tabs
                            defaultActiveKey="0"
                            id="justify-tab-example"
                            className="mb-3 serviceTab"
                            justify
                            onSelect={(k) => {
                                // setStatus(k);
                                // (k == 0) ? onClickHandler(ServiceImg) :
                                //     (k == 1) ? onClickHandler(ServiceImg_1) :
                                //         (k == 2) ? onClickHandler(ServiceImg_2) : onClickHandler(ServiceImg_3)
                                {
                                    data.s_card?.map((item, idx) => {
                                        if (k == idx) {
                                            onClickHandler(item.s_card_image.url);
                                        }
                                    })
                                }
                            }}
                        >
                            {data.s_card?.map((item, idx) => {

                                return (
                                    <Tab eventKey={idx} title={item.s_card_title}>

                                        <div className={style.tabContent}>

                                            <PrismicRichText field={item.s_card_description}/>
                                            <Row>
                                                {
                                                    (item.s_card_list_content.length > 6) ? item.s_card_list_content.map((value, idx) => (
                                                        <Col lg={6}>
                                                            <div className={style.boldContent}>
                                                                <img src={VectorArrow}/>
                                                                <label>{value.text} </label>
                                                            </div>
                                                        </Col>
                                                    )) : item.s_card_list_content.map((value, idx) => (
                                                        <Col lg={12}>
                                                            <div className={style.boldContent}>
                                                                <img src={VectorArrow}/>
                                                                <label>{value.text} </label>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </div>
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>
            </div>
        </section>
    );
    return section;
};

export default ServicesSection;


const ms_list = [
    {
        icon: VectorArrow,
        description:
            "Electrical Services",
    },
    {
        icon: VectorArrow,
        description:
            "Locksmith Services",
    },
    {
        icon: VectorArrow,
        description:
            "Ground Maintenance",
    },
    {
        icon: VectorArrow,
        description:
            "Pest Control",
    },
    {
        icon: VectorArrow,
        description:
            "Painting",
    },
    {
        icon: VectorArrow,
        description:
            "Handyman Services",
    },
    {
        icon: VectorArrow,
        description:
            "Commercial Cleaning",
    },
    {
        icon: VectorArrow,
        description:
            "Roof Plumbing Services",
    },
    {
        icon: VectorArrow,
        description:
            "Plumbing and Gas Services",
    },
];

const es_list = [
    {
        icon: VectorArrow,
        description:
            "Waste Management ",
    },
    {
        icon: VectorArrow,
        description:
            "Rubbish Removal",
    },
    {
        icon: VectorArrow,
        description:
            "Eco-Friendly Consumables",
    },
];
const mw_list = [
    {
        icon: VectorArrow,
        description:
            "Gyprock work",
    },
    {
        icon: VectorArrow,
        description:
            "Extensions",
    },
    {
        icon: VectorArrow,
        description:
            "Plastering",
    },
    {
        icon: VectorArrow,
        description:
            "Carpentry ",
    },
    {
        icon: VectorArrow,
        description:
            "Joinery Work",
    },
    {
        icon: VectorArrow,
        description:
            "Refurbishment",
    },
    {
        icon: VectorArrow,
        description:
            "Fit Out work ",
    },
    {
        icon: VectorArrow,
        description:
            "Fencing / Retaining wall",
    },
    {
        icon: VectorArrow,
        description:
            "Renovation",
    }
];
const id_list = [
    {
        icon: VectorArrow,
        description:
            "Operational Staff (Concierge)",
    },
    {
        icon: VectorArrow,
        description:
            "Labour Supply",
    }
];

import React, {useEffect} from "react";
import StyledComponent from "../../../../components/StyledComponent";
import AboutUsImg_1 from "../../../../images/aboutUsImg1.png"
import * as style from './index.module.scss';
import AOS from "aos";
import "aos/dist/aos.css";
import {PrismicRichText} from "@prismicio/react";

const AboutUsSection = ({data}) => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div id="aboutUs" className="section-center">
            <section className={style.sectionBox}>
                <h1 className={style.mobileTitle}>About Us</h1>
                <div className={style.contentBox}>
                    <div className={style.imgBox}>
                        <img src={data?.au_image}
                             data-aos="zoom-in-down"/>
                        <div className={style.greenBox}
                             data-aos="zoom-in-up"></div>
                    </div>
                    <div className={style.parmBox}>
                        <h1 className={style.destopTitle}>
                            <PrismicRichText field={data?.au_title}/>
                        </h1>
                        <p>
                            <PrismicRichText field={data?.au_description}/>
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutUsSection;
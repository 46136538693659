import React, { useState } from "react";
import StyledComponent from "../../../../components/StyledComponent";
import { Col, Form, Row } from "react-bootstrap";
import { homePageContactSchema } from "../../../../utils/validationSchemas";
import StyledFormikInput from "../../../../components/Input/StyledFormikInput";
import StyledButton from "../../../../components/Button/StyledButton";
import StyledFormikMessage from "../../../../components/StyledFormikMessage";
import ContactImg from "../../../../images/icons/contactImg.svg";
import { Formik } from "formik";
import axios from "axios";
import * as style from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import copyicon from "./images/icon.svg";
import { useStaticQuery, graphql, Link } from "gatsby";

const ContactUs = ({ data }) => {
  //fetching address from prismic
  const address = useStaticQuery(graphql`
    query {
      allPrismicGlobals {
        nodes {
          dataRaw
        }
      }
    }
  `);

  const fetchingAddress = address?.allPrismicGlobals?.nodes[0]?.dataRaw.address;
  // console.log(fetchingAddress[0].text);

  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const email = "admin@kayafm.com.au";
    navigator.clipboard
      .writeText(email)
      .then(() => {
        // The text was successfully copied to the clipboard
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Set it back to false after 3 seconds
      })
      .catch((err) => {
        // Unable to copy
        console.error("Error copying text: ", err);
      });
  };

  return (
    <section id="contactUs" className={style.sectionBox}>
      {/* <div className="section-center"> */}
      <section>
        <Row className={style.mainTitlesection}>
          <Col>
            <div className={style.mainTitle}>
              <PrismicRichText field={data?.cu_title} />
            </div>
          </Col>
        </Row>
        <Row className={`${style.main_row}  gx-5 mx-auto`}>
          <Col lg={7}>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                service: "",
                phone: "",
              }}
              validationSchema={homePageContactSchema}
              onSubmit={async (values, { setStatus }) => {
                setStatus({});
                // await new Promise((r) => setTimeout(r, 2000));
                await axios
                  .post("/api/send-mail", {
                    fields: [
                      { key: "First Name", value: values.firstName },
                      { key: "Last Name", value: values.lastName },
                      { key: "Email", value: values.email },
                      { key: "Contact Number", value: values.phone },
                      { key: "Service", value: values.service },
                    ],
                  })
                  .then(() => {
                    setSuccess(true);
                    setStatus({
                      success: true,
                      type: "success",
                      message:
                        "Successfully sent. One of our agents will get back to you soon.",
                    });
                  })

                  .catch(() => {
                    setFail(true);
                    setStatus({
                      success: false,
                      type: "danger",
                      message: "Failed to send the message. Please retry.",
                    });
                  });
              }}
            >
              {({ handleSubmit, isSubmitting, status, dirty, ...others }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={"6"} className={style.formInputCelLeft}>
                        <StyledFormikInput
                          name={"firstName"}
                          type={"text"}
                          label={"Your first name*"}
                        />
                      </Col>
                      <Col xs={"6"} className={style.formInputCelRight}>
                        <StyledFormikInput
                          name={"lastName"}
                          type={"text"}
                          label={"Your last name"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"6"} className={style.formInputCelLeft}>
                        <StyledFormikInput
                          name={"email"}
                          type={"text"}
                          label={"Your email address*"}
                        />
                      </Col>
                      <Col xs={"6"} className={style.formInputCelRight}>
                        <StyledFormikInput
                          name={"phone"}
                          type={"text"}
                          label={"Your telephone number*"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"6"} className={style.formInputCelLeft}>
                        <StyledFormikInput
                          name={"company"}
                          type={"text"}
                          label={"Your company name"}
                        />
                      </Col>
                      <Col xs={"6"} className={style.formInputCelRight}>
                        <StyledFormikInput
                          name={"service"}
                          type={"text"}
                          as={"select"}
                          label={"Select service"}
                          defaultValue={""}
                        >
                          <option value={""}>Please select</option>
                          <option value="Maintenance Services">
                            Maintenance Services
                          </option>
                          <option value="Environmental Services">
                            Environmental Services
                          </option>
                          <option value="Minor Works">Minor Works</option>
                          <option value="Staffing Services">
                            Staffing Services
                          </option>
                          <option value="Other">Other</option>
                        </StyledFormikInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"12"}>
                        <StyledFormikInput
                          name={"message"}
                          as={"textarea"}
                          type={"text"}
                          label={"How can we help you?"}
                        />
                      </Col>
                    </Row>
                    <StyledButton
                      title={data.cu_button_text}
                      progress={isSubmitting}
                      disabled={status?.success}
                    />
                    <StyledFormikMessage status={status} />
                  </Form>
                );
              }}
            </Formik>
            {/*<div className={style.contactBox}>*/}
            {/*<div>*/}
            {/*<img src={ContactImg}/>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<p>Speak with an <span>expert</span></p>*/}
            {/*<p>(212) 913-9141</p>*/}
            {/*</div>*/}
            {/*</div>*/}
          </Col>
          <Col lg={4}>
            <div className={style.address}>
              <div className={style.adddressnum}>
                <h4
                  className={`${style.title} heading `}
                  style={{ color: "black" }}
                >
                  Address
                </h4>
                <PrismicRichText field={fetchingAddress} />
              </div>
              <div className="copy-item">
                <h4
                  className={`${style.emailtitle} heading `}
                  style={{ color: "black", fontWeight: "700" }}
                >
                  Email us
                </h4>
                <div className={style.copyied}>
                  <a
                    href="mailto:admin@kayafm.com.au"
                    style={{ textDecoration: "underline" }}
                  >
                    admin@kayafm.com.au
                  </a>
                  <div className={style.emailcopied} onClick={copyToClipboard}>
                    <img
                      src={copyicon}
                      alt=""
                      loading="lazy"
                      style={{ width: "18px", height: "18px" }}
                    />
                    <p
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        color: "#1B4332",
                        whiteSpace: "nowrap",
                      }}
                      className={style.copytxt}
                    >
                      {copied ? "Copied" : "Copy Email"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {/* </div> */}
    </section>
  );
};

export default ContactUs;
